export default {
  colors: {
    text: "#000",
    background: "#fff",
  },
  breakpoints: ["768px", "1025px", "1290px"],
  space: [4, 8, 12, 16, 24, 32, 64, 96, 128, 256, 512],
  fonts: {
    body: "Raleway, sans-serif",
    heading: "Lato, sans-serif",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  useBodyStyles: "true",
  useBorderBox: "true",
  styles: {
    root: {
      // uses the theme values provided above
      fontFamily: "body",
      fontWeight: "body",
      margin: "0px",
    },
    p: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: [3, 2, 2],
      color: "black",
    },
    h1: {
      fontFamily: "heading",
      lineHeight: "heading",
      textAlign: "center",
      fontWeight: "400",
      fontSize: "24px",
      borderBottom: "1px solid #030523",
      display: "table",
      margin: "16px auto 24px auto",
      padding: "8px",
    },
    h2: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [6],
      color: "white",
      mb: [3, 4],
      textAlign: "center",
    },
    h3: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [4],
      mb: [3, 4],
    },
    h4: {
      fontFamily: "body",
      lineHeight: "heading",
      fontWeight: "600",
      fontSize: [3],
      color: "black",
      mb: [4, 5],
    },
  },
};
